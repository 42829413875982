<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      Driver Information
      <b-button slot="right" type="is-primary" @click="assignRoute">
        Assign Route
      </b-button>
    </hero-bar>
    <section class="section is-main-section">
      <b-progress v-if="isLoading" size="is-large" show-value>
        Fetching Driver Information
      </b-progress>
      <b-tabs type="is-boxed" v-else>
        <b-tab-item label="Details" icon="playlist-edit" class="is-success">
          <form @submit.prevent="submit">
            <div class="columns">
                <div class="column is-three-fifths">
                <b-field label="Driver Name" expanded>
                    <b-input
                    minlength="3"
                    v-model="driver.name"
                    name="driverName"
                    required
                    />
                </b-field>
                </div>
                <div class="column">
                <b-field label="Username">
                    <b-input
                    minlength="3"
                    v-model="driver.username"
                    name="driverUsername"
                    required
                    />
                </b-field>
                </div>
                <div class="column">
                <b-field label="Contact">
                    <b-input
                    minlength="8"
                    v-model="driver.contact"
                    name="driverContact"
                    required
                    />
                </b-field>
                </div>
            </div>
            <hr />
            <b-field grouped position="is-right">
              <div class="control">
                <b-button @click="exit">Cancel</b-button>
              </div>
              <div class="control">
                <b-button @click="resetPassword" type="is-danger">Reset Password</b-button>
              </div>
              <div class="control">
                <b-button native-type="submit" type="is-primary" :disabled="!infoHasChanges">Submit</b-button>
              </div>
            </b-field>
          </form>
        </b-tab-item>
        <b-tab-item label="Records" icon="format-list-bulleted">
          <assigned-route-list :label="this.oldDriverInfo.name" mode="driver" :key="assignedListKey" ref="assignedRouteList"/>
        </b-tab-item>
      </b-tabs>
    </section>
  </div>
</template>

<script>
import driver from '@/services/driver'
import assignRoute from '@/services/assign_route'

import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import AssignedRouteList from '@/components/AssignedRouteList'
import AssignRoute from '@/modals/AssignRoute'

export default {
  name: 'CustomerInfo',
  data () {
    return {
      openedTab: 0,
      assignedListKey: 1,
      id: null,
      isLoading: false,
      driver: {},
      // used for checking if there is changes
      oldDriverInfo: {}
    }
  },
  components: {
    TitleBar,
    HeroBar,
    AssignedRouteList
  },
  async mounted () {
    this.id = this.$route.params.id
    this.isLoading = true
    await this.loadDriverInfo()
    this.isLoading = false
  },
  methods: {
    async loadDriverInfo () {
      try {
        const driverInfo = await driver.getDriverById(this.id)
        this.oldDriverInfo = { ...driverInfo }
        this.driver = { ...driverInfo }
      } catch (error) {
        this.$buefy.snackbar.open({
          message: error,
          queue: false
        })
        this.$router.push({ path: '/drivers' })
      }
    },
    submit () {
      if (this.infoHasChanges) { this.updateDriver() }
    },
    async updateDriver () {
      try {
        const { name, username, contact } = this.driver
        await driver.updateDriverById(this.id, {
          name, username, contact
        })
        this.oldDriverInfo = { ...this.driver }
        this.$buefy.snackbar.open({
          message: 'Driver updated successfully',
          queue: false
        })
      } catch (error) {
        this.$buefy.snackbar.open({
          message: error.message,
          queue: false
        })
      }
    },
    exit () {
      // check if there is data added
      if (this.infoHasChanges) {
        this.$buefy.dialog.confirm({
          message: 'Changes will not be saved. Are you sure you want to exit?',
          onConfirm: () => {
            this.$router.go(-1)
          }
        })
      } else {
        this.$router.go(-1)
      }
    },
    resetPassword () {
      // check if there is data added
      this.$buefy.dialog.confirm({
        message: 'Are you sure you want to reset driver password?',
        onConfirm: async () => {
          try {
            await driver.resetDriverPassword(this.id)
            this.$buefy.snackbar.open({
              message: 'Password reset successfully',
              queue: false
            })
          } catch (error) {
            this.$buefy.snackbar.open({
              message: error.message,
              queue: false
            })
          }
        }
      })
    },
    assignRoute () {
      this.$buefy.modal.open({
        component: AssignRoute,
        parent: this,
        props: {
          driverId: parseInt(this.id)
        },
        events: {
          onAssign: async (returnData) => {
            try {
              const result = await assignRoute.assignRouteToDriver(returnData.data)
              returnData.close()
              this.$buefy.snackbar.open({
                message: 'Route assigned successfully',
                queue: false
              })
              this.openedTab = 1
              this.assignedListKey += 1
              this.$refs.assignedRouteList.viewAssigned(result.assignedRoute.id)
            } catch (error) {
              this.$buefy.snackbar.open({
                message: error.message,
                queue: false
              })
            }
          }
        }
      })
    }
  },
  computed: {
    infoHasChanges () {
      return JSON.stringify(this.oldDriverInfo) !== JSON.stringify(this.driver)
    },
    titleStack () {
      return ['Drivers', 'View']
    }
  }
}
</script>
